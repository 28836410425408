import * as util from './index';
import * as CONST from '../ActionTypes';

/**
 * 顧客チェックバッジ
 */
export const CustomerCheckBadges = (accessToken) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/check_badges`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.CUSTOMER_CHECK_BADGES_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.CUSTOMER_CHECK_BADGES_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * ドライバーチェックバッジ
 */
export const DriverCheckBadges = (accessToken) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/check_badges`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.DRIVER_CHECK_BADGES_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.DRIVER_CHECK_BADGES_FAILURE, error));
      dispatch(util.unLoading());
    });
};
/**
 * 結果クリア
 */
export const clearCheckBadges = () => (dispatch) => {
  dispatch(util.actionType(CONST.CLEAR_CHECK_BADGES, null));
};
