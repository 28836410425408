/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense } from 'react';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import routes from '../routes';
import Layout from '../components/Layout';
import RefreshToken from '../components/RefreshToken';
import PublicRoute from '../components/PublicRoute';
import CustomerRoute from '../components/CustomerRoute';
import DriverRoute from '../components/DriverRoute';
import store from '../store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/ie11.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const App = () => (
  <Provider store={store}>
    <RefreshToken>
      <Suspense fallback={loading}>
        <Router>
          {
            routes.filter((route) => route.auth === 'none').map((route) => (
              <PublicRoute
                key={route.path}
                path={route.path}
                name={route.name}
                role={route.role}
                component={route.component}
              />
            ))
          }
          {
            routes.filter((route) => route.auth === 'customer').map((route) => (
              <CustomerRoute
                key={route.path}
                path={route.path}
                name={route.name}
                role={route.role}
                component={route.component}
              />
            ))
          }
          {
            routes.filter((route) => route.auth === 'member').map((route) => (
              <DriverRoute
                key={route.path}
                path={route.path}
                name={route.name}
                role={route.role}
                component={route.component}
              />
            ))
          }
        </Router>
      </Suspense>
    </RefreshToken>
  </Provider>
);

export default App;
