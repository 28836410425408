/* eslint-disable max-len */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { shallowEqual, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Footer from './Footer';
import './layout.css';
import Header from './Header';
import TruckIco from '../images/assets/truck003.png';
import MessageIco from '../images/assets/message001.png';
import FileIco from '../images/assets/file001.png';
import BulletinIco from '../images/assets/icon-bulletin.png';

interface LayoutProps {
  children: ReactNode;
}

const DriverLayout = ({ children }: LayoutProps) => {
  const role = useSelector((state: any) => state.role.role, shallowEqual);
  const loading = useSelector(
    (state: any) => state.loading.loading, shallowEqual,
  );
  const checkBadge = useSelector(
    (state: any) => state.checkBadge.checkBadges,
    shallowEqual,
  );
  const isManager = useSelector(
    (state: any) => state.authDriver.isManager,
    shallowEqual,
  );
  // アクティブになったリンクは色を反転させる
  // const ActiveStyles = {
  //   background: '#59cd90',
  //   color: 'white',
  //   fontWeight: 'bold',
  // };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading..."
    >
      <Header role={role} />
      <main>
        <div className="member-container">
          <div className="wrapper-main">
            <div className="sidebar">
              <ul>
                <li>
                  <div className="sidebar-menu">
                    <Link
                      className="flex-container"
                      activeClassName="active"
                      to="/member/delivery_request"
                    >
                      {/* <li className=${selected ? "selected" : ""}> */}

                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={TruckIco}
                        alt="truck icon"
                      />
                      見積依頼一覧
                      {
                      // eslint-disable-next-line max-len
                      (checkBadge?.new_answer === true) && (
                        <span className="badge badge-circle badge-pill  badge-driver badge-outline-danger">
                          {checkBadge?.total_new_answer}
                        </span>
                      )
                    }
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="sidebar-menu">
                    <Link
                      className="flex-container"
                      activeClassName="active"
                      to="/member/suggestion"
                    >

                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={FileIco}
                        alt="file icon"
                      />
                      <span>提案履歴</span>
                      {
                      // eslint-disable-next-line max-len
                      (checkBadge?.update_suggestion === true && checkBadge?.content_update_suggestion.length < 8) && (
                        <span className="text-badge" style={{ color: 'red', paddingLeft: 80 }}>
                          {checkBadge?.content_update_suggestion}
                        </span>
                      )
                    }
                      {
                      (checkBadge?.update_suggestion === true && checkBadge?.content_update_suggestion.length >= 8) && (
                        <span className="text-badge" style={{ color: 'red', paddingLeft: 50 }}>
                          {checkBadge?.content_update_suggestion}
                        </span>
                      )
                    }
                    </Link>
                  </div>
                </li>
                {isManager === true && (
                <li>
                  <div className="sidebar-menu">
                    <Link
                      className="flex-container"
                      activeClassName="active"
                      to="/member/all_suggestion"
                    >

                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={FileIco}
                        alt="file icon"
                      />
                      <span>利用者の提案履歴</span>
                    </Link>
                  </div>
                </li>
                )}
                <li>
                  <div className="sidebar-menu">
                    <Link
                      activeClassName="active"
                      to="/member/contents"
                    >
                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={MessageIco}
                        alt="message icon"
                      />
                      コンテンツ
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="sidebar-menu">
                    <Link
                      activeClassName="active"
                      to="/member/notifications"
                    >
                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={BulletinIco}
                        alt="bulletin icon"
                      />
                      掲示板
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="sidebar-menu">
                    <Link
                      activeClassName="active"
                      to="/member/supporting"
                    >
                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={BulletinIco}
                        alt="bulletin icon"
                      />
                      賛助会員一覧
                    </Link>
                  </div>
                </li>
                {isManager === true && (
                <li>
                  <div className="sidebar-menu">
                    <Link
                      activeClassName="active"
                      to="/member/list"
                    >
                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={BulletinIco}
                        alt="bulletin icon"
                      />
                      利用者一覧
                    </Link>
                  </div>
                </li>
                )}
                {isManager === true && (
                <li>
                  <div className="sidebar-menu">
                    <Link
                      activeClassName="active"
                      to="/member/manager_notifications"
                    >
                      <img
                        className="badge-img"
                        style={{ width: '32px' }}
                        src={BulletinIco}
                        alt="bulletin icon"
                      />
                      代表者掲示板
                    </Link>
                  </div>
                </li>
                )}
              </ul>
            </div>
            <div className="content-main">{children}</div>
          </div>
        </div>
      </main>
      <Footer />
    </LoadingOverlay>
  );
};

DriverLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DriverLayout;
