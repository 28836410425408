import React from 'react';

// auth
const LoginCustomer = React.lazy(
  () => import('./views/customer/pages/login/Login'),
);
const LoginDriver = React.lazy(
  () => import('./views/driver/pages/login/Login'),
);
const RigisterCustomer = React.lazy(
  () => import('./views/customer/pages/register/Register'),
);
const RigisterDriver = React.lazy(
  () => import('./views/driver/pages/register/Register'),
);
const RegisterManagerDriver = React.lazy(
  () => import('./views/driver/pages/register/RegisterManager'),
);

// customer
const ProfileCustomer = React.lazy(
  () => import('./views/customer/pages/profile/Profile'),
);
const EditProfileCustomer = React.lazy(
  () => import('./views/customer/pages/profile/Edit'),
);
const ChangePasswordCustomer = React.lazy(
  () => import('./views/customer/pages/changePassword/ChangePassword'),
);
const ChangeEmailCustomer = React.lazy(
  () => import('./views/customer/pages/changeEmail/ChangeEmail'),
);
const ChangeEmailDriver = React.lazy(
  () => import('./views/driver/pages/changeEmail/ChangeEmailDriver'),
);
const SendResetPasswordCustomer = React.lazy(
  () => import('./views/customer/pages/resetPassword/SendResetPassword'),
);
const CommonEmail = React.lazy(
  () => import('./pages/common'),
);
const VerifyEmail = React.lazy(
  () => import('./pages/verifyEmail'),
);
const ResetPasswordCustomer = React.lazy(
  () => import('./views/customer/pages/resetPassword/ResetPassword'),
);
const HomeCustomer = React.lazy(() => import('./views/customer/home/Home'));
const ListDeliveryRequestTemporarily = React.lazy(
  () => import('./views/customer/deliveryRequest/ListTemporarily'),
);
const DetailDeliveryRequestCustomer = React.lazy(
  () => import('./views/customer/deliveryRequest/Detail'),
);
const DetailDriverCustomer = React.lazy(
  () => import('./views/customer/driver/Detail'),
);
const PostDeliveryRequestCustomer = React.lazy(
  () => import('./views/customer/deliveryRequest/Post'),
);
const EditDeliveryRequestCustomer = React.lazy(
  () => import('./views/customer/deliveryRequest/Edit'),
);
const EditDeliveryRequestTemporarily = React.lazy(
  () => import('./views/customer/deliveryRequest/EditTemporarily'),
);
const MessageCustomer = React.lazy(
  () => import('./views/customer/message/Message'),
);
const MessageDetailCustomer = React.lazy(
  () => import('./views/customer/message/MessageDetail'),
);
const ContactCustomer = React.lazy(
  () => import('./views/customer/pages/contact/Contact'),
);
const ListDeliveryRequestCustomer = React.lazy(
  () => import('./views/customer/deliveryRequest/List'),
);
// dirver
const ProfileDriver = React.lazy(
  () => import('./views/driver/pages/profile/Profile'),
);
const EditProfileDriver = React.lazy(
  () => import('./views/driver/pages/profile/Edit'),
);
const ChangePasswordDriver = React.lazy(
  () => import('./views/driver/pages/changePassword/ChangePassword'),
);
const SendResetPasswordDriver = React.lazy(
  () => import('./views/driver/pages/resetPassword/SendResetPassword'),
);
const ResetPasswordDriver = React.lazy(
  () => import('./views/driver/pages/resetPassword/ResetPassword'),
);
const HomeDriver = React.lazy(() => import('./views/driver/home/Home'));
const ListDeliveryRequestDriver = React.lazy(
  () => import('./views/driver/deliveryRequest/List'),
);
const DetailDeliveryRequestDriver = React.lazy(
  () => import('./views/driver/deliveryRequest/Detail'),
);
const SuggestionDriver = React.lazy(
  () => import('./views/driver/suggestions/Suggestions'),
);
const AllSuggestionDriver = React.lazy(
  () => import('./views/driver/suggestions/AllSuggestions'),
);
const SuggestionDetailDriver = React.lazy(
  () => import('./views/driver/suggestions/Detail'),
);
const ContentsDriver = React.lazy(
  () => import('./views/driver/contents/Contents'),
);
const ContentsDetailDriver = React.lazy(
  () => import('./views/driver/contents/ContentDetail'),
);
const CustomerPrice = React.lazy(
  () => import('./views/price/Price'),
);
const DriverPrice = React.lazy(
  () => import('./views/price/DriverPrice'),
);
const Company = React.lazy(
  () => import('./views/company/Company'),
);
const NotificationsDriver = React.lazy(
  () => import('./views/driver/notification/Notifications'),
);
const NotificationsDetail = React.lazy(
  () => import('./views/driver/notification/NotificationDetail'),
);
const AddNotification = React.lazy(
  () => import('./views/driver/notification/AddNotification'),
);
const ContactDriver = React.lazy(
  () => import('./views/driver/pages/contact/Contact'),
);
const NotificationTop = React.lazy(
  () => import('./pages/notificationTop'),
);
const HistoryNotificationTop = React.lazy(
  () => import('./pages/historyNotificationTop'),
);
const Supporting = React.lazy(
  () => import('./views/driver/memberSupporting/Supporting'),
);
const ListMember = React.lazy(
  () => import('./views/driver/member/List'),
);
const frequentlyAskedQuestionMembers = React.lazy(
  () => import('./views/driver/pages/frequentlyAskedQuestion/frequentlyAskedQuestions'),
);
const frequentlyAskedQuestionCustomer = React.lazy(
  () => import('./views/customer/pages/frequentlyAskedQuestion/frequentlyAskedQuestions'),
);
const routes = [
  // Login Customer
  {
    path: '/customer/login',
    exact: true,
    name: '荷主サイン',
    component: LoginCustomer,
    auth: 'none',
    role: 'customer',
  },
  // Login Driver
  {
    path: '/member/login',
    exact: true,
    name: '物流業者サイン',
    component: LoginDriver,
    auth: 'none',
    role: 'member',
  },
  // Profile Customer
  {
    path: '/customer/me',
    exact: true,
    name: '荷主プロフィール',
    component: ProfileCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Edit Profile Customer
  {
    path: '/customer/me/edit',
    exact: true,
    name: '荷主プロフィール編集',
    component: EditProfileCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Profile Driver
  {
    path: '/member/me',
    exact: true,
    name: '物流業者プロフィール',
    component: ProfileDriver,
    auth: 'member',
    role: 'member',
  },
  // Edit Profile Driver
  {
    path: '/member/me/edit',
    exact: true,
    name: '物流業者プロフィール編集',
    component: EditProfileDriver,
    auth: 'member',
    role: 'member',
  },
  // Register Customer
  {
    path: '/customer/register',
    exact: true,
    name: '荷主登録',
    component: RigisterCustomer,
    auth: 'none',
    role: 'customer',
  },
  // Change Password Customer
  {
    path: '/customer/change_password',
    exact: true,
    name: '荷主パスワード変更',
    component: ChangePasswordCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Send Reset Password Customer
  {
    path: '/customer/send_reset_password',
    exact: true,
    name: '荷主パスワードリセット送信',
    component: SendResetPasswordCustomer,
    auth: 'none',
    role: 'customer',
  },
  // Common Email
  {
    path: '/customer/common',
    exact: true,
    name: '荷主パスワードリセット送信',
    component: CommonEmail,
    auth: 'none',
    role: 'customer',
  },
  // Common Notification
  {
    path: '/notification/:id',
    exact: true,
    name: '荷主パスワードリセット送信',
    component: NotificationTop,
    auth: 'none',
    role: 'customer',
  },
  // history Notification
  {
    path: '/history-notification/:role',
    exact: true,
    name: 'リスト通知',
    component: HistoryNotificationTop,
    auth: 'none',
    role: 'customer',
  },
  // Common Notification
  {
    path: '/member/common',
    exact: true,
    name: '荷主パスワードリセット送信',
    component: VerifyEmail,
    auth: 'none',
    role: 'member',
  },
  // Reset Password Customer
  {
    path: '/customer/reset_password',
    exact: true,
    name: '荷主パスワードリセット',
    component: ResetPasswordCustomer,
    auth: 'none',
    role: 'customer',
  },
  // Register Driver
  {
    path: '/member/register',
    exact: true,
    name: '物流業者登録',
    component: RigisterDriver,
    auth: 'none',
    role: 'member',
  },
  {
    path: '/member/register_manager',
    exact: true,
    name: '代表者の方はこちら',
    component: RegisterManagerDriver,
    auth: 'none',
    role: 'manager-member',
  },
  // Change Password Driver
  {
    path: '/member/change_password',
    exact: true,
    name: '物流業者パスワード変更',
    component: ChangePasswordDriver,
    auth: 'member',
    role: 'member',
  },
  // Send Reset Password Driver
  {
    path: '/member/send_reset_password',
    exact: true,
    name: '物流業者パスワードリセット送信',
    component: SendResetPasswordDriver,
    auth: 'none',
    role: 'member',
  },
  // Reset Password Driver
  {
    path: '/member/reset_password',
    exact: true,
    name: '物流業者パスワードリセット',
    component: ResetPasswordDriver,
    auth: 'none',
    role: 'member',
  },
  // Home Customer
  {
    path: '/customer',
    exact: true,
    name: '荷主トップページ',
    component: HomeCustomer,
    auth: 'none',
    role: 'customer',
  },
  // Home Driver
  {
    path: '/member',
    exact: true,
    name: '物流業者トップページ',
    component: HomeDriver,
    auth: 'none',
    role: 'member',
  },
  // List Delivery Request Customer
  {
    path: '/customer/delivery_request',
    exact: true,
    name: '見積依頼履歴',
    component: ListDeliveryRequestCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // List Delivery Request Temporarily
  {
    path: '/customer/delivery_request_temporarily',
    exact: true,
    name: '下書き見積依頼',
    component: ListDeliveryRequestTemporarily,
    auth: 'customer',
    role: 'customer',
  },
  // List Delivery Request Driver
  {
    path: '/member/delivery_request',
    exact: true,
    name: '見積依頼一覧',
    component: ListDeliveryRequestDriver,
    auth: 'member',
    role: 'member',
  },
  // Detail Delivery Request Customer
  {
    path: '/customer/delivery_request/:id',
    exact: true,
    name: '見積依頼履歴詳細',
    component: DetailDeliveryRequestCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Detail Delivery Request Driver
  {
    path: '/member/delivery_request/:id',
    exact: true,
    name: '見積依頼詳細',
    component: DetailDeliveryRequestDriver,
    auth: 'member',
    role: 'member',
  },
  // Detail Driver Customer
  {
    path: '/customer/member/:id',
    exact: true,
    name: '物流業者詳細',
    component: DetailDriverCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Post Delivery Request Customer
  {
    path: '/customer/delivery_request/post',
    exact: true,
    name: '見積依頼投稿',
    component: PostDeliveryRequestCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Post Delivery Request Customer
  {
    path: '/customer/delivery_request/:id/edit',
    exact: true,
    name: '見積依頼編集',
    component: EditDeliveryRequestCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Post Delivery Request Customer
  {
    path: '/customer/delivery_request_temporarily/:id/edit',
    exact: true,
    name: '見積依頼編集',
    component: EditDeliveryRequestTemporarily,
    auth: 'customer',
    role: 'customer',
  },
  // Message to Customer
  {
    path: '/customer/message',
    exact: true,
    name: '提案メッセージ',
    component: MessageCustomer,
    auth: 'customer',
    role: 'customer',
  },

  //List suggestion of Driver
  {
    path: '/member/suggestion',
    exact: true,
    name: '提案履歴',
    component: SuggestionDriver,
    auth: 'member',
    role: 'member',
  },
  //List suggestion of Driver
  {
    path: '/member/all_suggestion',
    exact: true,
    name: '利用者の提案履歴',
    component: AllSuggestionDriver,
    auth: 'member',
    role: 'member',
  },
  //Message detail of driver
  {
    path: '/member/suggestion/:id',
    exact: true,
    name: '提案詳細',
    component: SuggestionDetailDriver,
    auth: 'member',
    role: 'member',
  },
  //Message detail of customer
  {
    path: '/customer/message/:id',
    exact: true,
    name: 'メッセージ',
    component: MessageDetailCustomer,
    auth: 'customer',
    role: 'customer',
  },
  //Price page
  {
    path: '/customer/price',
    exact: true,
    name: 'ご利用料金',
    component: CustomerPrice,
    auth: 'none',
    role: 'customer',
  },
  //Price page
  {
    path: '/member/price',
    exact: true,
    name: 'ご利用料金',
    component: DriverPrice,
    auth: 'none',
    role: 'member',
  },
  //Contents of Driver
  {
    path: '/member/contents',
    exact: true,
    name: 'コンテンツ',
    component: ContentsDriver,
    auth: 'member',
    role: 'member',
  },
  //Contents details of Driver
  {
    path: '/member/content/:id',
    exact: true,
    name: 'コンテンツ',
    component: ContentsDetailDriver,
    auth: 'member',
    role: 'member',
  },
  // Change Email to Customer
  {
    path: '/customer/change_email',
    exact: true,
    name: 'メールアドレスを変更',
    component: ChangeEmailCustomer,
    auth: 'customer',
    role: 'customer',
  },
  // Change Email to Driver
  {
    path: '/member/change_email',
    exact: true,
    name: 'メールアドレスを変更',
    component: ChangeEmailDriver,
    auth: 'member',
    role: 'member',
  },
  //Notifications of Driver
  {
    path: '/member/notifications',
    exact: true,
    name: '掲示板',
    component: NotificationsDriver,
    auth: 'member',
    role: 'member',
  },
  //Notifications of Driver
  {
    path: '/member/notification/:id',
    exact: true,
    name: '掲示板',
    component: NotificationsDetail,
    auth: 'member',
    role: 'member',
  },
  // Add Notifications of Driver
  {
    path: '/member/notification/create',
    exact: true,
    name: '新規投稿する',
    component: AddNotification,
    auth: 'member',
    role: 'member',
  },
  {
    path: '/customer/contact',
    exact: true,
    name: 'お問い合わせ',
    component: ContactCustomer,
    auth: 'customer',
    role: 'customer',
  },
  {
    path: '/member/contact',
    exact: true,
    name: 'お問い合わせ',
    component: ContactDriver,
    auth: 'member',
    role: 'member',
  },
  //Company page
  {
    path: '/company',
    exact: true,
    name: '運営会社：一般社団法人中小企業物流協会',
    component: Company,
    auth: 'none',
    role: 'customer',
  },
  //Member Supporting
  {
    path: '/member/supporting',
    exact: true,
    name: '賛助会員一覧',
    component: Supporting,
    auth: 'member',
    role: 'member',
  },
  //List Member
  {
    path: '/member/list',
    exact: true,
    name: '利用者一覧',
    component: ListMember,
    auth: 'member',
    role: 'manager-member',
  },
  //Notifications of manager
  {
    path: '/member/manager_notifications',
    exact: true,
    name: '代表者掲示板',
    component: NotificationsDriver,
    auth: 'member',
    role: 'manager-member',
  },
  // Add Notifications of manager
  {
    path: '/member/manager_notification/create',
    exact: true,
    name: '代表者掲示板投稿',
    component: AddNotification,
    auth: 'member',
    role: 'manager-member',
  },
  {
    path: '/member/frequently_asked_questions',
    exact: true,
    name: 'よくあるご質問',
    component: frequentlyAskedQuestionMembers,
    auth: 'member',
    role: 'member',
  },
  {
    path: '/customer/frequently_asked_questions',
    exact: true,
    name: 'よくあるご質問',
    component: frequentlyAskedQuestionCustomer,
    auth: 'customer',
    role: 'customer',
  },
];

export default routes;
