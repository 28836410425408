/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeRole } from '../redux/actions/role';

const PublicRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeRole(rest.role));
  }, [dispatch]);

  return (
    <>
      <Component {...rest} />
    </>
  );
};

export default PublicRoute;
