/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { changeRole } from '../redux/actions/role';
import DriverLayout from './DriverLayout';
import { DriverCheckBadges, clearCheckBadges } from '../redux/actions/checkBadges';

const DriverRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(
    (state: any) => state.authDriver.isLoggedIn, shallowEqual,
  );

  useEffect(() => {
    dispatch(changeRole(rest.role));
  }, [dispatch]);

  const accessToken = useSelector(
    (state: any) => state.authDriver.accessToken,
    shallowEqual,
  );
  useEffect(() => {
    dispatch(DriverCheckBadges(accessToken));
    dispatch(clearCheckBadges());
  }, [accessToken, dispatch]);

  if (!isLoggedIn) {
    navigate('/member/login');
  }
  return (
    <DriverLayout>
      <Component {...rest} />
    </DriverLayout>
  );
};

export default DriverRoute;
